// @import "./sass/utils/root";
// @import "./sass/utils/breakPoints";

// @import "./sass/fonts";
// @import "./sass/icons";

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Arial", sans-serif;

  // color: #000;
  font-size: 14px;
  line-height: 1.33;
  letter-spacing: 0.1em;

  background-color: #ffffff;
  color: rgba(44, 44, 44, 1);
  margin: 0;
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: auto;
}

#root {
  height: 100%;
  max-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  outline: none;
  border: none;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0;
}

th,
tr {
  border: 0;
  padding: 0;
  margin: 0;

  //user-select: none;
}

ul,
li {
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
}

address {
  font-style: normal;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

// .visually-hidden {
//   position: absolute;
//   width: 0;
//   height: 0;
//   margin: -1px;
//   border: 0;
//   padding: 0;
//   white-space: nowrap;
//   clip-path: inset(100%);
//   clip: rect(0 0 0 0);
//   overflow: hidden;
// }

// ----------- Animations ----------- //

@keyframes BackdropAnimation {
  from {
    scale: 0.8;
    opacity: 0;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}
